<template>
  <navbar>
    <h6 class="title-navbar">
      Tentang Kami
    </h6>
  </navbar>

  <div class="container about__wrapper">
    <h4 class="title">
      Amazing Sedekah Indonesia
    </h4>
    <p class="tagline">
      # Menebar Kebaikan
    </p>
  </div>
  <div class="container about__wrapper">
    <div v-if="loading">
      <div class="lazy-loading-content image-container">
      <Skeleton width="100" height="274"/>
      </div>
    </div>
    <div class="content" v-if="!loading && content != null" v-html="content">
    </div>
    <div class="content" v-if="!loading && content == null">
      <p>
        <strong>Satukan Kebaikanmu Bersama Kami</strong>
      </p>
      <p>
        Yayasan Amazing Sedekah Indonesia (Amazing Sedekah Foundation) adalah lembaga penggalangan
        dana online dengan situs bernama Amazingsedekah.com.
      </p>
      <p>
        Dibentuk sebagai wadah kepedulian untuk turut menyelesaikan sebagian permasalahan
        sosial-ekonomi-pendidikan masyarakat melalui penggalangan dana dan pendistribusiannya dalam
        program-program Sedekah, Wakaf dan Zakat, dan memungkinkan bekerjasama dengan NGO nasional
        maupun internasional
      </p>
      <p>
        <strong>Visi</strong>
      </p>
      <ol>
        <li>
          <p>
            Menjadikan sedekah sebagai gaya hidup masyarakat.
          </p>
        </li>
        <li>
          <p>
            Menjadi lembaga filantropi Islam yang terpercaya.
          </p>
        </li>
      </ol>
      <p>
        <strong>Misi</strong>
      </p>
      <ol>
        <li>
          <p>
            Menebarkan literasi keutamaan sedekah.
          </p>
        </li>
        <li>
          <p>
            Memfasilitasi terbentuknya sistem terintegrasi dan terpercaya yang memudahkan masyarakat
            dalam bersedekah.
          </p>
        </li>
        <li>
          <p>
            Menghadirkan program-program ril dan berdampak luas manfaatnya
          </p>
        </li>
      </ol>
      <p>
        <strong>Pengesahan Menteri Kehakiman & HAM</strong>
      </p>
      <p>
        Surat Keputusan Nomor AHU-0017254.AH.01.04. Tahun 2020
      </p>
      <p>
        <strong>Alamat</strong>
      </p>
      <p>
        Jl. H. Montong No. 48 RT 05 RW 02 Ciganjur Jakarta Selatan 12630
      </p>
      <p>
        <strong>TIM KERJA</strong>
      </p>
      <p>
        Amazing Sedekah Foundation dikelola oleh para profesional dalam bidangnya untuk memastikan berjalannya aktivitas sesuai rencana kerja serta terjaga akuntabilitasnya.
      </p>
      <p>
        <strong>Pembina</strong>
        <em>
          Drs. H. Nassam S. Suharta
        </em>
      </p>
      <p>
        <strong>Pengawas</strong>
        <em>
          Dra. Hj. Endah Widiani, M.Pd.
        </em>
      </p>
      <p>
        <strong>Managing Director</strong>
        <em>
          Nanu Utama, S.Hut., MM
        </em>
      </p>
      <p>
        <strong>Finance</strong>
        <em>
          Titin Murtiani, SP.
        </em>
      </p>
      <p>
        <strong>Administration</strong>
        <em>
          M. Fikri Ramadhan, SPOk
        </em>
      </p>
      <p>
        <strong>Prog. & Comm. Dev.</strong>
        <em>
          Nanu Utama, S.Hut., MM.
        </em>
      </p>
    </div>
  </div>
  <Menu />
</template>

<script>
import Navbar from '../components/TransactionComponent/NavbarTransaction.vue'
import Menu from '../components/Home/Menu.vue'
import Skeleton from '../components/Skeleton/Skeleton.vue'

export default {
  components: { Navbar, Menu, Skeleton },
  data(){
    return{
      loading: true,
      content: null,
    }
  },
  mounted() {
    this.getContent()
  },
  methods: {
    getContent() {
      this.loading = true;
      const contentSlug = 'about'
      this.$http
        .get(`${process.env.VUE_APP_API_URL}contents/${contentSlug}`)
        .then(response => {
          if(response.data && response.data.content){
            this.content = response.data.content;
            this.loading = false;
          }
        })
        .catch(error => {
          this.loading = false;
          //   console.log(error.response)
        })
    }
  }
}
</script>